import './App.css';
import React, {useState} from "react";
import QRCode from 'react-qr-code';

function App() {

    const [text,setText] = useState("adamslife.hu");

    function handleChange(e){
        setText(e.target.value)
    }

  return (
    <div className="App">

        <header className="App-header">
            <h1>QR Code Generator</h1>
            <QRCode value={text} />

            <div>
                <p>Enter your text here</p>
                <input type="text" value={text} onChange={(e)=>{handleChange(e)}} />
                <button>Generate</button>
            </div>

        </header>

    </div>
  );
}

export default App;
